import React from 'react';
import img from './Frame.png'
import { Box, Typography } from '@mui/material';
import  Button  from '@mui/material/Button';
import {Contx} from '../../../App'
import { Link } from 'react-router-dom';

const Cards = (props) => {
    const {lang} = React.useContext(Contx)
    return (
        <div style={{
            width: '425px',
            height: '256px',
            background: `url(${img}) no-repeat center center/cover`,
            position: 'relative',
            direction: lang === 'en' ? 'ltr' : 'rtl',
            marginBottom:'200px',
        }}>

            <div style={{
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'start',
                position: 'absolute',
                
            }}>
                <div style={{
                    margin:'20px',
                    border:'solid 1px #D9D9D9',
                    padding:'10px',
                    borderRadius:'10px',

                }}>{props.item.location}</div>
                <div style={{
                   margin:'20px',
                   border:'solid 1px #D9D9D9',
                   padding:'10px',
                   marginLeft:'0px',
                   borderRadius:'10px',
                }}>{
                    props.item.created
                }</div>
                <div style={{
                    margin:'20px',
                    border:'solid 1px #D9D9D9',
                    padding:'10px',
                    borderRadius:'10px',
                    marginLeft:'0px'
                }}>{
                    props.item.employment_status
                }</div>
            </div>


            <Typography style={{
                position: 'absolute',
                top: '90px',
                left: '21px',
                
               
        
                fontWeight: 'bold',
                fontSize: '22px',
                lineHeight: '36px',
               
            }}>
                {
                    lang === 'ar' ? props.item.title_ar : lang === 'en' ? props.item.title_en : props.item.title_ku
                }
            </Typography>

            <Typography style={{
                position: 'absolute',
                top: '120px',
                left: '21px',
               
               
                fontWeight: 'lighter',
                fontSize: '14px',
                lineHeight: '25px',
                maxWidth:'300px',
                textAlign:'start',
                color:'#777',
                
               
            }}>
               {
                    lang === 'ar' ? props.item.excerpt_ar : lang === 'en' ? props.item.excerpt_en : props.item.excerpt_ku
               }
            </Typography>

            <Box>
                <Link to={`/jobs/${props.item.id}`} style={{textDecoration:'none'}}>

               
                <Button  variant="outlined" style={{
                    position: 'absolute',
                    top: '190px',
                    left: '21px',
                    background: 'none',
                    color: '#F3974D',
                    fontWeight: 'bold',
                    fontSize: '16px',
                    lineHeight: '25px',
                    padding: '5px 10px',
                    borderRadius: '16px',
                    borderColor: '#F3974D',
                    cursor: 'pointer',
                }}>{'Apply >'} </Button> </Link>
            </Box>
            
        </div>
    );
}

export default Cards;
