import { Box, Grid } from '@mui/material';
import React from 'react';
import { Container } from 'react-bootstrap';
import img from './1.svg'
import img2 from './2.svg'
import img1 from './Group1.png'

const Section1 = (props) => {
    const data = content[props.lang]
    return (
        <Box sx={{
        background:props.lang === 'en' ? 'url('+img+') no-repeat left top ' : 'url('+img2+') no-repeat right top ',
        backgroundSize: 'fit',
        
        direction: props.lang === 'en' ? 'ltr' : 'rtl',

      

        
        
        }} >

            <Container>
                <Grid container spacing={3} sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                }}>

                    <Grid item xs={12} md={6} sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <img src={img1} width={'80%'} alt="" />
                    </Grid>

                    <Grid item xs={12} md={6} sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                       
                    }}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                          
                            flexDirection: 'column',
                            textAlign: 'start',
                        }}>
                            <h1 style={{
                                
                                fontWeight: '600',
                                fontSize: '40px',
                                margin: '0px',
                                textAlign: 'start',
                            }}>{data.h} <span style={{color:'rgba(240, 125, 33, 1)'}}>{data.h2}</span> </h1>
                            <p style={{
                                color: 'rgba(0, 0, 0, 0.5)',
                                fontWeight: '500',
                                fontSize: '18px',
                                margin: '0px',
                                maxWidth: '580px',
                                lineHeight: '32px',
                                paddingTop: '10px',
                                textAlign:'justify'
                                
                            }}>
                                {data.p}
                                </p>
                        </Box>
                    </Grid>
                    </Grid>
            </Container>

        </Box>
    );
}

export default Section1;



const en = {
    h:`About`,
    h2:`US`,
    p:` Shubber SB is a large pharmaceutical distribution company in Iraq that sells branded medications. It was founded in 2010 and is part of the Shubber Group, a family-owned business that has been in business since 1992.
    Shubber SB is a successful company that is known for its flexibility and ability to adapt to different business models. It distributes medications to all segments of the pharma business in Iraq, and it represents more than 5 multinational companies.
                               `,
}


const ar ={
    h:`من `,
    h2:`نحن`,
    p:`مكتب شُبر العلمي من اكبر شركات العراق في مجال توزيع الادوية التي تبيع أدوية ذات العلامات التجارية الرصينة. تأسست في عام 2010 وهي جزء من مجموعة شُبر، وهي شركة عائلية مملوكة تعمل منذ عام 1992. مكتب شُبر العلمي هي شركة ناجحة تُعرف بمرونتها وقدرتها على التكيف مع نماذج أعمال مختلفة. توزع الأدوية لجميع قطاعات صناعة الأدوية في العراق وتمثل 8 من اكبر شركات تصنيع الادوية عالميا `,
}

const ku ={
    h:`  `,
    h2:`هاوبه‌شه‌كان `,

    p:`كۆمپانیاى "شوبه‌ر ئێس بى" كۆمپانیایه‌كى گه‌وره‌ى دابه‌شكردنى ده‌رمانه‌ له‌ عێراق كه‌ به‌رهه‌مى ماركه‌دار ده‌فرۆشێت. كۆمپانیا له‌ ساڵى 2010دا دامه‌زراوه‌ و به‌شێكه‌ له‌ گرووپى كۆمپانیاكانى "شوبه‌ر"، كه‌ كۆمپانیایه‌كه‌ خێزان خاوه‌ندێتیى ده‌كات و له‌ ساڵى 1992ـه‌وه‌ كار ده‌كات.

    كۆمپانیاى "شوبه‌ر ئێس بى" كۆمپانیایه‌كى سه‌ركه‌وتووه‌ كه‌ ناسراوه‌ به‌ نه‌رم‌ونیانى و تواناى له‌سه‌ر خۆگونجان له‌گه‌ڵ شێوازه‌ جیاوازه‌كانى بوارى بازرگانى. كۆمپانیاكه‌ ده‌رمان به‌سه‌ر هه‌موو لایه‌نه‌كانى بزنسى ده‌رمان له‌ عێراق دابه‌ش ده‌كات و نوێنه‌رایه‌تیى زیاتر له‌ 5 كۆمپانیاى فره‌نه‌ته‌وه‌ ده‌كات.
     
    `,
}


const content = {
    en,
    ar,
    ku
}
