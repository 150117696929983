import React from 'react';
import img from './1.svg'

const Loder = () => {
    return (
        <div style={{
            width: '100%',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',

        }}>
           
           <img className='loder' src={img} width={'60%'} alt="" />
        </div>
    );
}

export default Loder;

