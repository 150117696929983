import { Box } from '@mui/material';
import React from 'react';
import { Contx } from '../../App';

const Not = () => {
    const { lang } = React.useContext(Contx)
    const data = content[lang]

    const [show, setShow] = React.useState(true)

    

    return (
        <Box 
        
        display={
            show ? 'block' : 'none'
        }
        
        sx={{
            bgcolor: '#fff',
            borderRadius: '24px',
            position: 'fixed',
            zIndex: '999',
            bottom: '0',
            right: '0',
            width: '400px',
            height: '230px',
            direction: lang === 'en' ? 'ltr' : 'rtl',
            textAlign:'start',
            
           

        }} >
            
            <div 
            onClick={()=>{
                setShow(false)
            }
            }
            >
            <Box
            
            
            
            
            sx={{
               
                
               position:'relative',
                top:'-0px',
                right:'0px',
                
                maxWidth:'70px',
                p:2,
                bgcolor:'#F07D21',
                borderRadius: lang === 'en' ? '24px 0px 24px 0px' : '0px 24px 0px 24px',
                cursor:'pointer',
                
                
            }}>
            {
                data.btn
            }
            </Box>
            </div>

           <Box p={2} pt={0}>
           <h3>
                {data.h}
            </h3>

            <p>
                {data.p+'  '} <br />
                <a href="tel:+9647809285114">
               {' +964 780 928 5114'}
                </a>
            </p>
            <p>
                {data.p2+'  '}
                <a href="mailto:info@alshubber.com">
                info@alshubber.com
                </a>
            </p>
           </Box>

        </Box>
    );
}

export default Not;


const en = {
    h:`Important Notice`,
    p:`For any Adverse Event, Drug Safety Complaint, or Quality Issue call us on `,
    p2:`or email us at`,
    btn:'close'
}


const ar = {
    h:`تنويــــــه`,
    p:`لتبليغ عن أي نتيجة سلبية أو ضرر ناجم عن جودة او استعمال الأدوية، يرجى الاتصال على`,
    p2:`او من خلال البريد الإلكتروني`,
    btn:'اغلاق'
}

const ku = {
    h:`ئاگاداربوونه‌وه‌`,
    p:`بۆ هه‌ر هه‌ڵسه‌نگاندنه‌وه‌یه‌كی زۆر یان گواستنه‌وه‌یه‌كی ده‌رمانی، تكایه‌ په‌یوه‌ندیمان بكه‌ن`,
    p2:`یان بۆ ئیمه‌یلی`,
    btn:'داخستن'
}

const content = {
    en,
    ar,
    ku
}