import { Box } from '@mui/material';
import Container from 'react-bootstrap/Container';
import React from 'react';
import logo from './Group.png'

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { NavLink } from 'react-router-dom';
import { Contx } from '../../App'

function NavScrollExample() {
  const stylenav = { fontSize: '20px', fontWeight: '700', }
  const navitemen = ['Home', 'About Us', 'Partners', 'Gallery', 'Jobs', 'News', 'Certificate', 'Contact Us']
  const navitemar = ['الرئيسية', 'من نحن', 'الشركاء', 'المعرض', 'الوظائف', 'الاخبار', 'الشهادات', 'اتصل بنا']
  const navitemku = ['سه‌ره‌كى', 'هاوبه‌شه‌كان ', 'هه‌واڵه‌كان', 'بڕوانامه‌كان', 'گه‌له‌رى', 'كاره‌كان', 'ده‌رباره‌ى ئێمه‌', 'په‌یوه‌ندیمان پێوه‌ بكه‌ن']
  
   const navlink = ['/', 'about', 'partners', 'gallery', 'jobs', 'news', 'certificate', 'contact']

  const contx = React.useContext(Contx)

  const navitem = contx.lang === 'en' ? navitemen : contx.lang === 'ar' ? navitemar : navitemku
  const handllang = (e) => {
    e.preventDefault()
    contx.setLang(e.target.value)

  }

  const [close, setclose] = React.useState('')
  

  return (

    <Navbar expand="lg" onToggle={
      (e) => {setclose(!close)}} expanded={close} dir={contx.lang === 'en' ? 'ltr' : 'rtl'} style={{
      backgroundColor: 'white',
      position: 'sticky',
      top: '0',
      width: '100%',
      zIndex: '1000',
    }} >
      <Container >
        <Navbar.Brand href="#"><img src={logo} width={'110px'} alt="" /></Navbar.Brand>





        <Navbar.Toggle  aria-controls="navbarScroll justify-content-end " />
        <Navbar.Collapse id="navbarScroll"  >
          <Nav
            className="me-auto my-2 my-lg-0 justify-content-center flex-grow-1 pe-3"
            style={{ maxHeight: '500px' }}
            navbarScroll
            onSelect={(e) => {
              setclose(false)
            }
            }
          >

            {
              navitem.map((item, index) => {
                return <NavLink data-bs-toggle="collapse" 
                
                activeClassName="active" 
                
                to={navlink[index]} style={{
                  textDecoration: 'none', color: 'black'
                }}>
                  <p className='m-2' style={stylenav}>
                    {item}
                  </p>
                </NavLink>
              }
              )


            }



            <select name="" id=""
              style={{
                marginInlineStart: '5vw', float: 'inline-end',
                border: 'none',
                textAlign: 'center',
                fontSize: '20px',
                color: '#3E8343',
                outline: 'none'


              }} onChange={handllang} >
              <option value="en">English</option>
              <option value="ar">عربي</option>
              <option value="ku">كردي</option>


            </select>


          </Nav>

        </Navbar.Collapse>




      </Container>
    </Navbar>

  );
}

export default NavScrollExample;