import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { Container, Form } from 'react-bootstrap';
import img from './bg.png';
import { Contx } from '../../../App';
import api from '../../../api/api';
import emailjs from 'emailjs-com';

export const Contact = () => {
    const { lang } = React.useContext(Contx)
    const [contact, setContact] = React.useState({
        name: '',
        email: '',
        phone: '',
        message: '',
        find_us: 'any',
        address: 'any',


    })
    const [loading, setLoading] = React.useState(false)
    const formref = React.useRef(null)

    
    


    const handlsupmit = (event) => {
        setLoading(true);
        event.preventDefault();

        emailjs.send("service_6w62c43","template_2x6i8bu",{
            from_name: contact.name,
            from_phone: contact.phone,
            from_email: contact.email,
            message: contact.message,
            find_us: contact.find_us,
            

            }, 'vQ9tfUhCEgG94XuCk').then((result) => {
                console.log(result.text);
                setLoading(false);
                formref.current.reset();
            }).catch((error) => {
                console.log(error.text);
                setLoading(false);
            });

    }

    const form = new FormData()
    form.append('name', contact.name)
    form.append('email', contact.email)
    form.append('phone', contact.phone)
    form.append('message', contact.message)
    form.append('find_us', contact.find_us)
    form.append('address', contact.address)






    // const handlsupmit = (event) => {
    //     setLoading(true)
    //     event.preventDefault()
    //     api.post('/contact-us/', form).then(res => {
    //         console.log(res)
    //         setLoading(false)
    //         formref.current.reset();
    //     }).catch(err => {
    //         console.log(err)
    //         setLoading(false)
           


    //     })
    // }


    return (
        <div>
            <Container>

                <Grid container spacing={2} py={7}>
                    <Grid item xs={12} md={5}>
                        <Box py={4}>
                            <h2 style={{ fontWeight: '700', textAlign: 'start' }}>Get in <b style={{ color: '#F07D21', fontWeight: '700' }}>Touch</b>  </h2>
                            <Typography variant="body1" gutterBottom sx={{ textAlign: 'start', my: 2 }}>
                                For any Adverse Event, Drug Safety Complaint, or Quality Issue call us on
                               <a href="tel:+964 780 928 5114" style={{textDecoration:'none'}} > +964 780 928 5114 </a>

                                or email us at <a href="mailto:info@alshubber.com" style={{textDecoration:'none'}}>info@alshubber.com</a>
                            </Typography>

                            <Form onSubmit={handlsupmit} ref={formref}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">

                                    <Form.Control required type="text" placeholder="Enter your full name" onChange={
                                        (e) => {
                                            setContact({ ...contact, name: e.target.value })
                                        }
                                    } />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">

                                    <Form.Control required type="email" placeholder="Enter your email address" onChange={
                                        (e) => {
                                            setContact({ ...contact, email: e.target.value })
                                        }
                                    } />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">

                                    <Form.Control required type="text" placeholder="Enter your phone number" onChange={
                                        (e) => {
                                            setContact({ ...contact, phone: e.target.value })
                                        }
                                    } />
                                </Form.Group>
                                {/* How did you find us? */}
                                <Form.Select className="mb-3" onChange={
                                    (e) => {
                                        setContact({ ...contact,find_us: e.target.value })
                                    }
                                } aria-label="Default select example">
                                    <option hidden>How did you find us?</option>
                                    <option value="Facebook">Facebook</option>
                                    <option value="Linkedin">Linkedin</option>
                                    <option value="Youtube">Youtube</option>
                                    <option value="Other">Other</option>
                                </Form.Select>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">

                                    <Form.Control required as="textarea" rows={3} placeholder='Massege' onChange={
                                        (e) => {
                                            setContact({ ...contact, message: e.target.value })
                                        }
                                    } />
                                </Form.Group>


                                <button disabled={loading} onClick={handlsupmit} className="btn btn-primary col-12" style={{
                                    background: 'var(--brand-colors-orange-orange-500, #F07D21)',
                                    color: '#fff',
                                    border: 'none',
                                }}>Send </button>
                            </Form>
                        </Box>

                    </Grid>
                    <Grid item xs={12} md={7}>
                        <Box sx={{ position: 'relative', top: '0', minHeight: '550px' }}>


                            <img src={img} width={'35%'} alt="" style={{
                                position: 'absolute',
                                top: '0',
                                right: '0',
                                borderRadius: '0 50px 50px 0',
                                zIndex: '1',
                                height: '100%',
                            }} />

                            <Box sx={{
                                position: 'relative',
                                zIndex: '10',
                                top: '0',
                                left: '0',




                            }}>
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3333.7444671985845!2d44.414996460176695!3d33.32549537354988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1557810421a4d185%3A0xabfab09816394e57!2sShubber%20Scientific%20Bureau!5e0!3m2!1sar!2siq!4v1697903076193!5m2!1sar!2siq" style={{
                                    border: '0',
                                    width: '70%',
                                    height: '450px',
                                    zIndex: '10',
                                    position: 'absolute',
                                    top: '50px',
                                    right: '50px',
                                    borderRadius: '10px',
                                    boxShadow: '0 0 10px #00000033',
                                }} referrerpolicy="no-referrer-when-downgrade">dfghjkl;</iframe>
                            </Box>
                        </Box>

                    </Grid>
                </Grid>

            </Container>

        </div>
    );
};
