import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Overviow from './overviow';
import Applcation from './applcation';
import { useState } from 'react';

function NoAnimationExample(props) {
  const [key, setKey] = useState("Overview");

 
  const handleSelect2 = (key) => {
    setKey(key);
  }
  
  return (
    <Tabs
      defaultActiveKey="Overview"
      transition={false}
      id="tab"
      className="py-3 justify-content-center tab"
      onSelect={handleSelect2}

    
      activeKey={key}
      
      style={{border:'none',
        backgroundColor:'#fff',
        
    }}
      
     
    >
      <Tab  eventKey="Overview" title="Overview" >
        <Overviow job={props.job} activeKey={setKey} />
      </Tab>
      <Tab eventKey="Application" title="Application">
        <Applcation job={props.job}/>
      </Tab>
      
    </Tabs>
  );
}

export default NoAnimationExample;