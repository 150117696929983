import axios from "axios";
const api = axios.create({
    baseURL: "https://dashboard.shubbersb.com/api/",

});

export default api;


// https://dashboard.shubbersb.com/api/shubberadminuser/


