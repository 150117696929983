import React from 'react';
import Cardnew from './card';
import { Container } from 'react-bootstrap';
import { Grid } from '@mui/material';
import img from './1.png'
import { Contx } from '../../../../../App';
import api from '../../../../../api/api';
import { Link } from 'react-router-dom';


const News = () => {
    const { lang } = React.useContext(Contx)
    const [news, setNews] = React.useState([])

    const header = lang === 'en' ? 'News' : lang === 'ar' ? 'الأخبار' : 'هەواڵ'

    React.useEffect(() => {
        api.get('/news').then(res => {
            console.log(res.data)
            setNews(res.data.results)
        })
    }, [])
    return (
        <div>

            <Container>
                <h1>{header}</h1>

                <br />
                <Grid container spacing={3} sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                   
                
                }}>
                    
                   {
                          news.map((item,index)=>{
                            return(<>
                                {
                                    index <= 3 ?   <Grid item sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',

                             }}>


                             <Cardnew item={item}  lang={lang}/>


                            </Grid> : null
                                }</>
                            )
                          })
                   }
                </Grid>

                <Link to='/news' style={{
                    textDecoration:'none'
                }}>
                <div style={{
                    width:'fit-content',
                    height:'fit-content',
                    padding:'10px 20px',
                    borderRadius:'20px',
                    background:'rgba(62, 131, 67, 1)',
                    color:'white',
                    margin:'auto',
                    marginTop:'50px',
                    marginBottom:'50px',
                    cursor:'pointer'
                }}>
                    <p style={{
                        margin:'0px',
                        fontSize:'20px',
                        fontWeight:'600'
                    }}>View More</p>
                </div>
                </Link>
            </Container>
           
        </div>
    );
}

export default News;
