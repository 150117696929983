import React from 'react';
import Slide from "./sections/slide/slide";
import Atshubber from './sections/atshubber/atshubber';
import Mission from './sections/mission/mission';
import News from './sections/news/news';

const Home = () => {
    return (
        <div>
            
                <Slide/>
                <br /><br /><br />
           
          
                <Atshubber/>

               
                <Mission/>

                <br /><br /><br />

                <News/>



                <br /><br /><br />
            
            
        </div>
    );
}

export default Home;
