import React, { Component } from "react";
import Slider from "react-slick";
import img from './Display.png'
import arro from './arrow.svg'
import { Container } from "react-bootstrap";
import api from '../../../api/api';
import YouTube from "react-youtube";
export default class AsNavFor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null,
      video: []
    };
  }

  componentDidMount() {

    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,



    });


    api.get('/videos/').then(res => {

      
      this.setState({
        video: res.data.results
      })

    }).catch(err => {
      console.log(err)

    })


  }

  render() {






    return (
      <div>
        <Container style={{
          maxWidth: '85%',

        }}>










          <Slider arrows={true} lazyLoad="true" centerMode="true" centerPadding="0px" slidesToShow={1} slidesToScroll={1}


            nextArrow={<SampleNextArrow />}
            prevArrow={<SamplePrevArrow />}
            asNavFor={this.state.nav2}
            ref={slider => (this.slider1 = slider)}
          >


            {
              this.state.video && this.state.video.map((item, index) => {
                return (
                  <div key={index} style={{
                    position: 'relative',

                  }}>

                    <YouTube videoId={this.state.video} onReady={this._onReady}
                      style={{

                        height: '42vw',
                        borderRadius: '60px',
                      }}
                      opts={{
                        width: '90%',
                        height: '100%',

                      }} />


                  </div>
                )
              })
            }
          </Slider>

          <Slider {...settings} lazyLoad="true" centerMode="true" centerPadding="20px"
            asNavFor={this.state.nav1}
            ref={slider => (this.slider2 = slider)}
            slidesToShow={5}
            swipeToSlide={true}
            focusOnSelect={true}
          >
            {
              this.state.video.map((item, index) => {
                return (
                  <div key={index} >
                    <iframe style={{
                      borderRadius: '24px',
                      padding: '3%',
                      width: '100%',


                    }} src="https://www.youtube.com/embed/X2EkESrXvdc?list=RDX2EkESrXvdc" allow="autoplay; encrypted-media; picture-in-picture" title=" "  ></iframe>


                  </div>
                )
              })
            }

          </Slider>






        </Container>


      </div>
    );
  }
}








function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img src={arro} alt="" className={className}
      style={{ ...style, transform: 'scale(-3)' }}
      onClick={onClick} />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img src={arro} alt="" className={className}
      style={{ ...style, transform: 'scale(3)' }}
      onClick={onClick} />
  );
}





var settings = {

  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};