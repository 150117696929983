
import React from 'react';
// import img from './image4.png'
import img from './1.jpg'
import { Box, Button, Typography } from '@mui/material';
import { Container } from 'react-bootstrap';

import { Contx } from '../../../../../App';
import { Link } from 'react-router-dom';

const Slide = () => {
    const {lang} = React.useContext(Contx)

    const data = contint[lang]

    return (
        <div style={{
            position:'static',
            direction:lang==='en'?'ltr':'rtl',
        }}>


       
        <div style={{
           
         position:'relative',
        }}>
           
           <img src={img} style={{
                width:'100%',
                height:'auto',
                float:'right',
                
                
                
              

           }} alt="" />
           <div style={{
            position:'relative',
            width:'100%',
            height:'51.7vw',
            
            background:'linear-gradient(90deg, #6897cd66 10%, rgba(255, 255, 255, 20%) 100%)',
            right:'0',
            top:'0',
           }} >

           </div>

           {/* <div style={{
            position:'absolute',
            width:'100%',
            height:'6vw',
            
            background:'linear-gradient(0deg, #F1FAFF 25%, rgba(255, 255, 255, 15%) 100%)',
            right:'0',
            top:'50vw',
           }} >

           </div> */}
           <Container>

          
           <Box sx={{
            position:'absolute',
            top:'13vw',
          
           }} >
            <Typography variant='h2' fontWeight={'600'} align='start' sx={{maxWidth:'550px',fontSize:{md:'4.1vw',xs:'6vw'}}}>
            {data.h}
            </Typography>
            <Typography variant='inherit' fontWeight={400} color={'rgba(0, 0, 0, 0.6)'} align='justify' sx={{maxWidth:'570px',fontSize:{md:'120%',xs:'3.4vw'},lineHeight:{md:'1.5',xs:'1'},marginTop:'1vw',}}>
            {data.p}
            </Typography>

            <Box sx={{
                display:'flex',
                flexDirection:'row',
                justifyContent:'space-between',
                width:'50vw',
                
           
            }}>
                <Link   to='/about' style={{textDecoration:'none'}}>
            <Button variant='contained' sx={{
                background:'none',
                color:'#eaab6b',
                marginTop:'2vw',
                border:'1px solid #eaab6b',
                borderRadius:'16px',
                width:'175px',
                height:'56px',
                
                
                boxShadow:'none',
                '&:hover':{
                    background:'#1E56A0',
                    color:'white',
                    boxShadow:'none',
                }
            }} >
                {data.b}
            </Button>
            </Link>
            </Box>
    
            
           </Box>
            </Container>

          
        </div> </div>
    );
}

export default Slide;


const en = {
    h:`Your Health Is Our Priority`,
    p:` We are a large healthcare company with over 200 employees and 2 headquarters. We provide healthcare solutions in 18 Iraqi governorates and have multiple business units, including general medicine, CHC, vaccines, and specialty care.`,
    b:`Learn More`
}


const ar = {
    h:`صحتك هي أولويتنا`,
    p:`نحن من أكبر شركات الرعاية الصحية بأكثر من 200 موظف ومقرين رئيسيين. نقدم حلولًا صحية في 18 محافظة عراقية ولدينا وحدات أعمال متعددة، بما في ذلك الطب العام، والرعاية الصحية المجتمعية، واللقاحات، والرعاية المتخصصة.`,
    b:`اعرف أكثر`
}

const ku = {
    h:`دابه‌شكه‌رێكى پێشه‌نگى ده‌رمان`,
    p:`ئێمه‌ كۆمپانیایه‌كى گه‌وره‌ین له‌ كه‌رتى ته‌ندروستى كه‌ زیاتر له‌ 200 كارمه‌ندمان هه‌یه‌ له‌گه‌ڵ 2 باره‌گاى سه‌ره‌كى. داواكارییه‌كان بۆ كه‌رتى ته‌ندروستى له‌ 18 پارێزگاى عێراق-دا دابین ده‌كه‌ین و چه‌ندین یه‌كه‌ى كاركردنمان هه‌یه‌، له‌وانه‌ش ده‌رمانى گشتى و CHC و ڤاكسین و چاودێریى تایبه‌تمه‌ند.`,
     b:`زانیاری زیاتر`
}


const contint = {
    en,
    ar,
    ku
}
