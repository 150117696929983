import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { Container } from 'react-bootstrap';
import img from './scelton.png'
import { Contx } from '../../../../App'
import img1 from './s4.png'

const Section4 = () => {

    const { lang } = React.useContext(Contx)
    const data = content[lang]
    const par = data.p.split('<br/>')

    return (
        <div dir={
            lang === 'en' ? 'ltr' : 'rtl'
        } >
            <Container>
                <Grid container spacing={6}>
                    <Grid item xs={12} md={6} sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>

                        <img src={img1} style={{
                            width: '70%',
                            
                           
                        
                        }} alt="" />
                       
                    </Grid>
                    <Grid item xs={12} md={6} >
                        <Box sx={{
                            textAlign: 'start',
                            position: 'relative',
                        }}>

                            <img src={img} width={'100%'} alt="" />
                            <Box style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                
                                padding: '10%',
                            }}><h1> { 
                       data.h
                       }</h1>

                                <Typography style={{
                                    fontSize: {xs:'90%',md:'1.2rem'},
                                    textAlign:'justify'
                                }}>

                                    {
                                        par.map((item, index) => {
                                            return (
                                                <span key={index}>
                                                    {item}
                                                    <br />
                                                </span>
                                            )
                                        })
                                    }
                                    
                                </Typography>

                            </Box>

                        </Box>
                    </Grid>
                </Grid>
            </Container>
            <Box sx={{
              
                height: {xs:'200px',md:'0px'},
                
            }}></Box>
        </div>
    );
}

export default Section4;


const en = {
    h:`Dear Shubber SB Team`,
    p:`I am writing to you today to thank you for your hard work and dedication. Shubber SB has achieved great things in recent years, and it is all thanks to you.
    We are a leading pharmaceutical distribution company in Iraq, and we are proud to provide our customers with the highest quality products and services. We are also committed to making a positive impact on the lives of our employees and the communities we serve.
    Our success is due to our strong values of integrity, excellence, teamwork, and innovation. We believe that by living up to our values, we can create a better future for everyone.
    I am excited about the future of Shubber SB. We have a strong team in place, and we are well-positioned to continue to grow and succeed.
    Thank you again for your hard work and dedication. I am proud to be a part of this team <br/>
    <br/>
    Sincerely,<br/>
    Pharmaceutical Fatima Zaki Shubber<br/>`,
}

const ar = {
    h:`أعزائي فريق مكتب شُبر العلمي`,
    p:`
    
    أكتب لكم اليوم لأشكركم على جهودكم الجادة والمخلصة. حقق مكتب شُبر العلمي إنجازات كبيرة في السنوات الأخيرة، وذلك بفضلكم جميعا .
نحن الان نعتبر من قادة مجال توزيع الادوية في العراق، ونحن فخورون بتقديم منتجات وخدمات عالية الجودة لعملائنا. نحن أيضًا ملتزمون بتحقيق تأثير إيجابي على حياة موظفينا والمجتمعات التي نخدمها <br/> .
نجاحنا يعود إلى قيمنا القوية من النزاهة والتميز والعمل الجماعي والابتكار. نحن نعتقد أنه من خلال الالتزام بهذه القيم، يمكننا إنشاء مستقبل أفضل للجميع.
أنا متحمس لمستقبل مكتب شُبر العلمي . لدينا فريق قوي في مكانه، ونحن مستعدون بشكل جيد لمواصلة النمو والنجاح. 
<br/>
شكرًا لكم مرة أخرى على جهودكم الجادة والمخلصة. أنا فخورة بأن أكون جزءًا من هذا الفريق.
<br/>

مع خالص التحية،
<br/>

الصيدلانية فاطمة زكي شُبر
`,
}

const ku = {
    h:`په‌یامى به‌ڕێوه‌به‌رى گشتى:`,
    p:`
    به‌ڕێزان/ تیمى كۆمپانیاى "شوبه‌ر ئێس بى"...
<br/>
ئەمڕۆ ئه‌م په‌یامه‌تان ئاڕاسته‌ ده‌كه‌م بۆئەوەی سوپاسی ماندووبوون و دڵسۆزیتان بکەم. "شوبەر ئێس بی" لەم ساڵانەی دواییدا چه‌ندین ده‌ستكه‌وتی گەورەی بەدەستهێناوە و هەمووشی بە هیممه‌تی ئێوە بووه‌.<br/>
ئێمە کۆمپانیایەکی پێشەنگی دابەشکردنی دەرمانین لە عێراق و شانازی دەکەین به‌وه‌ی کە بەرهەم و خزمەتگوزاری به‌ بەرزترین کوالێتی پێشکەش بە کڕیارەکانمان دەکەین و به‌رده‌وام پابەندین بە درووستکردنی کاریگەریی ئەرێنی لە ژیانی کارمەندەکانمان و ئەو کۆمەڵگایانەی کە خزمەتیان بۆ ده‌گه‌یه‌نین.<br/>
نهێنیی سەرکەوتنمان دەگەڕێتەوە بۆ بەها بەهێزەکانمان کە بریتین لە دەستپاکی و نایابی و کاری تیم و داهێنان. ئێمە پێمانوایە كه‌ له‌ڕێگه‌ی پاپه‌ندبوونمان به‌ بەهاکانماندا، دەتوانین داهاتوویەکی باشتر بۆ هەمووان بونیاد بنێین.<br/>
من زۆر دڵخۆشم بە داهاتووی "شوبەر ئێس بی". تیمێکی بەهێزمان هەیە و لە دۆخێکی باشداین بۆ بەردەوامبوون لە گەشەکردن و سەرکەوتن.<br/>
دووبارە سوپاسی ماندووبوون و خۆبەخشیتان ده‌كه‌م. شانازی دەکەم کە بەشێکم لەم تیمه‌ دڵسۆزه‌.<br/>
<br/>
دڵسۆزتان،<br/>
فاتمە زەکی
`

}


export const content = {
    en,
    ar,
    ku
}


