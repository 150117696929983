import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { Container } from 'react-bootstrap';
import img1 from './1.svg'
import img2 from './2.svg'
import img3 from './3.svg'
import img4 from './4.svg'
import img5 from './5.svg'

import { Contx } from '../../../../../App';

const Atshubber = () => {
    const { lang } = React.useContext(Contx)
    const data = content[lang]

    
    const img = [img1, img2, img3, img4, img5]
    return (
        <div dir={lang === 'en'?'ltr':'rtl'}>
            <Container>
                <Typography variant='h2' fontWeight={'550'} align='start' sx={{ maxWidth: '550px',fontSize:{xs:'160%',md:'250%'},
            pt: { xs: 5, md: 0 },
            
            }} >
                    {data.h}
                </Typography>
                <Typography variant='body1' color={'rgba(0, 0, 0, 0.6)'} align='justify' sx={{ maxWidth: '550px' }}>
                    {data.p}
                </Typography>

                <Box m={7}>
                    <Grid container spacing={2} sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        gap: '20px'

                    }}>
                        {
                            [1, 2, 3, 4, 5].map((item, index) => (
                                <Grid item key={index} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>



                                    <Box sx={{ width: '200px', height: '350px', borderRadius: '20px', background: ' var(--basic-bg, #F7F7F7)', boxShadow: ' 0px 7px 9px 0px rgba(0, 0, 0, 0.07)', display: 'flex',flexDirection:'column' ,alignItems: 'center', justifyContent: 'center' }}>
                                        <img src={img[index]} className='col-4' alt="" />
                                        <Typography variant='h5' py={1} fontWeight={'600'}  align='center' >
                                            {data.list[index]}
                                        </Typography>

                                        <hr style={{
                                            width:'50px',
                                            height:'3px',
                                            backgroundColor:'rgba(218, 114, 30, 1)',
                                            border:'none',
                                            borderRadius:'10px'
                                        
                                        }} />


                                        <Typography variant='body2'p={2} color={'rgba(0, 0, 0, 0.6)'} align='center' sx={{ maxWidth: '550px' }}>
                                            {data.listp[index]}
                                        </Typography>
                                    </Box>



                                    <hr />

                                </Grid>
                            ))
                        }


                    </Grid>


                    <Typography variant='body1' mt={3} color={'rgba(0, 0, 0, 0.5)'} align='center' >
                        {data.footer}
                        </Typography>



                </Box>


            </Container>

        </div>
    );
}

export default Atshubber;



const en = {
    h: 'At Shubber SB',
    p: 'we are committed to the following values: ',
    list: ['Patient safety', 'Integrity', 'Excellence', 'Teamwork', 'Innovation'],
    listp:[
        `We believe that patient 
        safety is paramount, and 
        we take all necessary 
        precautions to ensure 
        that the products we 
        distribute are genuine 
        and of the highest quality.`,


        `We conduct all of our business with honesty and transparency. We are committed to ethical and responsible behavior in all of our dealings with our customers, employees, and business partners.`,
        `We are committed to providing our customers with the highest quality products and services. We strive to continuously improve our operations and to exceed our customers' expectations.`,
        `We believe that teamwork is essential to our success. We foster a culture of collaboration and respect, and we encourage our employees to work together to achieve our goals.`,
        `We are committed to innovation and to finding new ways to improve our business. We encourage our employees to be creative and to think outside the box.`


    ],

    footer:'These values guide us in everything we do at Shubber SB. We believe that by living up to our values, we can make a positive impact on the lives of our customers, employees, and the communities we serve.'
    
}


const ar = {
    h:'في مكتب شبر العلمي  ',
    p:'نحن ملتزمون بالقيم التالية:',
    list:['سلامة المريض','النزاهة','التميز','العمل الجماعي','الابتكار'],
    listp:[
        ` نعتقد أن سلامة المريض هي الأهم، ونتخذ جميع الاحتياطات اللازمة لضمان أن المنتجات التي نوزعها حقيقية وعالية الجودة`,
        `نُجري جميع أعمالنا بأمانة وشفافية. نحن ملتزمون بالسلوك الأخلاقي والمسؤول في جميع تعاملاتنا مع عملائنا وموظفينا وشركاء الأعمال`,
        ` نحن ملتزمون بتقديم منتجات وخدمات عالية الجودة لعملائنا. نسعى باستمرار لتحسين عملياتنا وتجاوز توقعات عملائنا`,
        `نعتقد أن العمل الجماعي أمر أساسي لنجاحنا. نحن نشجع على ثقافة التعاون والاحترام، ونشجع موظفينا على العمل معًا لتحقيق أهدافنا`,
        ` نحن ملتزمون بالابتكار وباستمرار في البحث عن وسائل جديدة لتطوير أعمالنا. نشجع موظفينا على أن يكونوا مبدعين ويفكروا خارج الصندوق`
        
    ],

    footer:`هذه القيم توجهنا في كل ما نقوم به في مكتب شُبر العلمي. نحن نعتقد أنه من خلال الالتزام بقيمنا، يمكننا أن نكون لهم تأثير إيجابي على حياة عملائنا وموظفينا والمجتمعات التي نخدمها.`
}

const ku = {
    h:'له شبر',
    p:'ماڵپەرەکەمان بە ئەم نرخانەی خوارەوە پێویستە:',
    list:[`سه‌لامه‌تیى نه‌خۆش `,'راستگۆیى','نایابى',`كارى تيم`,'داهێنان'],
    listp:[
        `ئێمه‌ له‌و بڕوایه‌داین كه‌ سه‌لامه‌تیى نه‌خۆش له‌ پێش هه‌موو شتێكه‌وه‌یه‌ و هه‌موو رێوشوێنێكى پێویست ده‌گرینه‌به‌ر به‌ وریاییه‌وه‌ كار ده‌كه‌ین بۆ دڵنیابوون له‌وه‌ى ئه‌و به‌رهه‌مانه‌ى كه‌ دابه‌شیان ده‌كه‌ین ئۆڕجیناڵن و كواڵیتییان به‌رزترینه‌.`,
        `ئێمه‌ هه‌موو كاره‌كانمان به‌ راستگۆیى و شه‌فافییه‌ت جێبه‌جێ ده‌كه‌ین. كۆمپانیاكه‌مان پاپه‌نده‌ به‌ ئاكاره‌كان و ره‌فتارى به‌رپرسیار له‌ هه‌موو مامه‌ڵه‌كانمان له‌گه‌ڵ كڕیار و كارمه‌ند و هاوبه‌شه‌كانمان.`,
        `ئێمه‌ پاپه‌ندین به‌ پێشكه‌شكردنى به‌رهه‌م و خزمه‌تگوزارییه‌كان به‌ به‌رزترین كواڵیتى بۆ كڕیاره‌كانمان و هه‌موو هه‌وڵێكمان ده‌خه‌ینه‌گه‌ڕ بۆ به‌ره‌وپێشه‌وه‌بردنى به‌رده‌وامى كاره‌كانمان و تێپه‌ڕاندنى پێشبینییه‌كانى كڕیاره‌كانمان.`,
        `ئێمه‌ له‌و بڕوایه‌داین كه‌ كارى تیم زۆر گرنگه‌ بۆ سه‌ركه‌وتنمان. كۆمپانیاكه‌مان كولتورى هاوكارى و رێزگرتن ده‌چێنێت و هانى كارمه‌نده‌كانمان ده‌ده‌ین پێكه‌وه‌ كار بكه‌ن بۆ گه‌یشتن به‌ ئامانجه‌كانمان.

        `,
        `ئێمه‌ پاپه‌ندین به‌ داهێنان و دۆزینه‌وه‌ى رێگه‌چاره‌ى نوێ بۆ باشتركردنى كاره‌كانمان. كۆمپانیاكه‌مان هانى كارمه‌نده‌كانمان ده‌دات داهێنه‌ر بن و فراوانتر بیر بكه‌نه‌وه‌.`,
    
    ],

    footer:`ئه‌م به‌هایانه‌ رێنیشانده‌رمانن له‌ هه‌موو كارێكدا له‌ "شوبه‌ر ئێس بى" ئه‌نجامى بده‌ین. ئێمه‌ پێمان وایه‌ كه‌ له‌ڕێگه‌ى پاپه‌ندبووماندا به‌ به‌هاكانمان، ده‌توانین كاریگه‌رییه‌كى ئه‌رێنى بخوڵقێنین له‌سه‌ر ژیانى كڕیار و كارمه‌نده‌كانمان و ئه‌و كۆمه‌ڵگانه‌ى كارى تێدا ده‌كه‌ین.`}

const content = {
    en,
    ar,
    ku
}











