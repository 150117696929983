import { Box, Grid } from '@mui/material';
import React from 'react';
import { Container } from 'react-bootstrap';
import img from './1.svg'
import Slide from './slider'
import { Contx } from '../../../App';
import api from '../../../api/api';
import { useParams } from 'react-router';

const Descriptionnews = () => {
    const { lang } = React.useContext(Contx)
    const [news, setNews] = React.useState([])
    
    const {id} = useParams()


    React.useEffect(() => {
        api.get(`/news/${id}`).then(res => {
            console.log(res.data)
            setNews(res.data)
        })
    }
    , [])

    return (
        <div  dir={ lang === 'en' ? 'ltr' : 'rtl'}>
            <Box sx={{
                width: '100%',

                borderTop: ' 0.6px solid var(--basic-stroke, #D9D9D9)',
                background: 'var(--basic-card, #FFF)',

                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                pt: 3,
               


            }}>

                <h3>
                    {
                        lang === 'en' ? news.title_en : lang === 'ar' ? news.title_ar : news.title_ku
                    }
                </h3>

                <div style={{
                    display: 'flex',
                    padding: '4px 12px',
                    alignitems: 'flex-start',
                    gap: '10px',
                    borderRadius: '3px',
                    background: 'var(--brand-colors-green-green-500, #3E8343)',
                    color: 'var(--basic-white, #FFF)',

                    fontFamily: 'Avenir',
                    fontStyle: 'normal',
                    fontWeight: '800',
                    fontSize: '14px',
                    lineHeight: 'normal',
                    margin: '5px 0px 10px 0px',

                }}>
                    {
                        news.created
                    }
                </div>

            </Box >



            <Container className='my-5'  >
                <Grid container spacing={7} >
                    <Grid item xs={12} sm={12} md={6}>
                        <p style={{
                        alignSelf: 'stretch',
                        color: 'var(--font-secoundary, rgba(0, 0, 0, 0.60))',
                        textAlign: 'justify',
                        
                        fontSize: '20px',
                        fontStyle: 'normal',
                        fontWeight: '350',
                        lineHeight: 'normal',

                        
                        }}>


                            
                                {
                                    lang === 'en' ? news.content_en : lang === 'ar' ? news.content_ar : news.content_ku
                                }
                           
                          </p>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>

                   {
                    news.content_images && <Slide item={news} lang={lang} images={news.content_images} />
                   } 

                   
                       



                </Grid>
            </Grid>

        </Container>

        </div >
    );
}

export default Descriptionnews;
