import { Grid, Typography } from '@mui/material';
import React from 'react';
import { Container } from 'react-bootstrap';
import img1 from './imgabout/1.png'
import img2 from './imgabout/2.png'
import img3 from './imgabout/3.png'
import img4 from './imgabout/4.png'
import { Contx } from '../../../../App'



const Section3 = () => {
    const { lang } = React.useContext(Contx)
    const data = content[lang]
    const img = [img1, img2, img3, img4]
    return (
        <div>
            <Container dir={
                lang === 'en' ? 'ltr' : 'rtl'
           
            }>

                <Grid container spacing={6}>
                    <Grid item xs={12}  md={5} sx={{
                       
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <Grid container spacing={3} sx={{
                           
                        }}>
                            {
                                img.map((item, index) => {
                                    return (
                                        <Grid item xs={6} >
                                            <img src={item} alt="" width="100%" />
                                        </Grid>
                                    )
                                })
                            }

                        </Grid>

                    </Grid>
                    <Grid item xs={12}   p={5} sx={{
                        textAlign: 'start',
                    }} md={6}>

                        <Typography variant="h4" >
                            {data.h}
                        </Typography>


                        <Typography variant="body1" sx={{
                            mt: 3,
                            textAlign:'justify'
                        }}>
                           {data.p1}
                             </Typography>

                        <Typography variant="body1" fontWeight={600} sx={{
                            mt: 2,
                        }}>
                           {
                                 data.list.map((item,index)=>{
                                      return(
                                        <li>{item}</li>
                                      )
                                 })
                           }

                        </Typography>

                        <Typography variant="body1"  sx={{
                            mt: 3,
                            textAlign:'justify'
                        }}>
                          {
                                data.p2
                          }
                            </Typography>

                    </Grid>


                </Grid>

            </Container>
        </div>
    );
}

export default Section3;


const en = {

    h:`Company Ethics Message`,
    p1:`At Shubber SB, integrity is our foundation. We believe in acting honestly, transparently, and consistently in all aspects of our business. We hold ourselves accountable for our actions and take pride in maintaining the highest ethical standards. We believe that ethical behavior is not negotiable, and we are committed to demonstrating it every day. This means:`,
    list:[
        `Being honest and transparent with our customers, employees, and business partners`,
        `Dealing with fairness and respect`,
        `Complying with all applicable laws and regulations`,
        `Avoiding conflicts of interest`,
        `Reporting any concerns about unethical conduct`,

    ],
    p2:`We expect our suppliers and business partners to adhere to the same ethical standards that we uphold. We engage in fair and responsible sourcing practices and collaborate with partners who share our commitment to ethical business conduct. We maintain a robust reporting mechanism to ensure that any concerns regarding ethical conduct or potential violations are promptly and thoroughly addressed. We encourage all employees to speak up and report any suspected violations without fear of retaliation. We believe that ethical business is good business. By acting with integrity, we build trust with our customers, employees, and the communities we serve. We also create a positive work environment where everyone feels valued and respected.`,

}


const ar = {
    h:`رسالة أخلاقية للشركة`,
    p1:`في مكتب شُبر العلمي، النزاهة هي أساسنا. نعتقد في السلوك بصدق وشفافية واستمرارية في جميع جوانب أعمالنا. نحن نعتبر أنفسنا مسؤولين عن أفعالنا ونفتخر بالحفاظ على أعلى معايير أخلاقية`,
     list:[
        `أن نكون صادقين وشفافين مع عملائنا وموظفينا وشركاء الأعمال`,
        `التعامل بالعدالة والاحترام`,
        `الامتثال لجميع القوانين واللوائح ذات الصلة`,
        `تجنب المصاحبة لصراعات المصالح`,
        `الإبلاغ عن أي مخاوف بشأن السلوك غير الأخلاقي`,
     ],
    p2:`نتوقع من موردينا وشركاء الأعمال الالتزام بنفس المعايير الأخلاقية التي نلتزم بها. نشارك في ممارسات مشتركة عادلة ومسؤولة في الشراء ونتعاون مع الشركاء الذين يشاركونا التزامنا بالسلوك التجاري الأخلاقي.
    نحتفظ بآلية تقريرية قوية لضمان معالجة أي مخاوف بشأن السلوك غير الأخلاقي أو الانتهاكات المحتملة بسرعة وبشكل كامل. نشجع جميع الموظفين على التحدث والإبلاغ عن أي انتهاكات مشتبه بها دون خوف من الانتقام.
    نعتقد أن الأعمال الأخلاقية هي أعمال جيدة. من خلال التصرف بنزاهة، نبني الثقة مع عملائنا وموظفينا والمجتمعات التي نخدمها. نقوم أيضًا بإنشاء بيئة عمل إيجابية حيث يشعر الجميع بالتقدير والاحترام.
    `}

const ku = {
    h:`په‌یام و ئاكاره‌كانى كۆمپانیا`,
    p1:`له‌ "شوبه‌ر ئێس بى"، ده‌ستپاكى و راستگۆیى بنه‌ماى كاره‌كانمانن. ئێمه‌ جه‌خت ده‌كه‌ین له‌سه‌ر مامه‌ڵه‌كردنى راستگۆییانه‌ و شه‌فاف و به‌رده‌وامبوون له‌هه‌موو لایه‌نه‌كانى كاره‌كانماندا. خۆمان به‌ به‌رپرسیار ده‌زانین له‌سه‌ر هه‌موو ره‌فتاره‌كانمان و شانازى به‌ پاراستنى به‌رزترین به‌ها ئه‌خلاقییه‌كان ده‌كه‌ین.`,
     list:[
        `راستگۆیى و شه‌فافییه‌ت له‌گه‌ڵ كڕیار و كارمه‌ند و هاوبه‌شه‌كانمان.`,
        `مامه‌ڵه‌كردن به‌ دادپه‌روه‌رى و رێزنواندن.`,
        `پاپه‌ندبوون به‌ هه‌موو یاسا و رێنماییه‌ كارپێكراوه‌كان.`,
        `دووركه‌وتنه‌وه‌ له‌ پێكدادانى به‌رژه‌وه‌ندییه‌كان.`,
        `ئاگاداركردنه‌وه‌ ده‌رباره‌ى هه‌ر نیگه‌رانییه‌ت له‌په‌یوه‌ند به‌ هه‌ڵسوكه‌وتى ناڕه‌وشتانه‌.`,
     ],
    p2:`پێشبینى له‌ دابینكه‌ران و هاوبه‌شه‌كانمان ده‌كه‌ین پاپه‌ند بن به‌ هه‌مان ئه‌و بنه‌ما ئه‌خلاقییانه‌ى كه‌ ئێمه‌ پیاده‌یان ده‌كه‌ین. ئێمه‌ پراكتیزه‌ى ره‌وا و به‌رپرسیارانه‌ جێبه‌جێ ده‌كه‌ین و هه‌ماهه‌نگى له‌گه‌ڵ هاوبه‌شه‌كانمان ده‌كه‌ین بۆ مه‌به‌ستى پاپه‌ندبوون به‌ ئاكاره‌ ئه‌خلاقییه‌كانى بزنس.

    میكانیزمى به‌هێزى ئاگاداركردنه‌وه‌ پیاده‌ ده‌كه‌ین بۆ دڵنیابوون له‌وه‌ى كه‌ هه‌ر نیگه‌رانییه‌ك ده‌رباره‌ى هه‌ر هه‌ڵسوكه‌وتێكى ناڕه‌وشت یان سه‌رپێچى هه‌بێت، ئه‌وه‌ خێرا و به‌ رێك‌وپێكى بخرێنه‌ڕوو و چاره‌سه‌ر بكرێن. هانى هه‌موو كارمه‌ندان ده‌ده‌ین بێ ترس له‌ تۆڵه‌ قسه‌ بكه‌ن و ئاگادارمان بكه‌نه‌وه‌ ده‌رباره‌ى هه‌ر سه‌رپێچییه‌كى ئه‌گه‌ركراو.
    ئێمه‌ له‌و بڕوایه‌داین كه‌ بزنس له‌سه‌ر بنه‌ماى ئه‌خلاقى باشترین بزنسه‌. كۆمپانیاكه‌مان متمانه‌ درووست ده‌كات له‌گه‌ڵ كڕیار و كارمه‌نده‌كانمان و هه‌روه‌ها ئه‌و كۆمه‌ڵگایانه‌ى كه‌ كاریان تێدا ده‌كه‌ین، ئه‌ویش له‌ڕێگه‌ى مامه‌ڵه‌ و ره‌فتاركردن به‌ ده‌ستپاكى و راستگۆیى. هه‌روه‌ها كه‌شوهه‌وایه‌كى ئه‌رێنى له‌ كاركردندا ده‌خوڵقێنین كه‌ تیایدا به‌ها و كه‌رامه‌تى هه‌مووان پارێزراوه‌.
    `,}


export const content = {
    en,
    ar,
    ku
}
