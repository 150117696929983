import { Box, Container } from '@mui/material';
import React from 'react';
// import { useDropzone } from 'react-dropzone';
import api from '../../../../api/api';


const Applcation = (props) => {
    const [fileinput, setFile] = React.useState(null);
    const [data, setData] = React.useState({
        name: '',
        phone: '',
        email: '',
        location: '',
        education: '',
        cover: '',
       
        job_offer: props.job.id,
    })

    const [loading, setLoading] = React.useState('send');
    const stylebox = {
        maxWidth: '800px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        alignItems: 'start',
        mt: 3
    }

    const styleinput = {
        width: '100%',
        height: '50px',
        borderRadius: '8px',
        border: '1px solid #D9D9D9',
        padding: '0 15px',

    }

    const handleFileDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        if (file) {
            setFile(file);
            // يمكنك تنفيذ الكود الخاص بتحميل الملف هنا

            alert('File dropped:' + file.name);
            console.log(file.name);
        }
    };

  


    const handlsupmit = (e) => {

        e.preventDefault();
        setLoading('sending...');
        const fileInput = fileinput;
       
        const name = data.name;
        const phone = data.phone;
        const email = data.email;
        const location = data.location;
        const education = data.education;
        const cover = data.cover;
        
      


        const form = new FormData();

        form.append('full_name', name);
        form.append('phone_number', phone);
        form.append('email', email);
        form.append('address', location);
        form.append('education', education);
        form.append('cover_letter', cover);
       
        form.append('job_offer', props.job.id);
        form.append('resume ', fileInput);


        api.post('/jobs-apply/', form).then((res) => {
            setLoading('send successfully');
            setTimeout(() => {
                setLoading('send');
                window.location.reload();
            }, 3000);
            console.log(res);
        }).catch((err) => {
            setLoading('send');
            console.log(err);
        })
    }

    return (
        <div>
            <Container className='my-5'  >
                <form onSubmit={handlsupmit}  >
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <Box sx={stylebox}>
                            <h4>Name*</h4>
                            <input type="text" required placeholder='Enter your name here' style={styleinput} onChange={
                                (e) => {
                                    setData({ ...data, name: e.target.value })
                                }

                            } />
                        </Box>
                        <Box sx={stylebox}>
                            <h4>Phone Number*</h4>
                            <input type="tel" required placeholder='Enter your Phone Number here' 
                            onChange={
                                (e) => {
                                    setData({ ...data, phone: e.target.value })
                                }
                            }
                            style={styleinput} />
                        </Box>
                        <Box sx={stylebox}>
                            <h4>E-Mail*</h4>
                            <input type='email' 
                            onChange={
                                (e) => {
                                    setData({ ...data, email: e.target.value })
                                }
                            }
                            placeholder='Enter your E-Mail here' required style={styleinput} />
                        </Box>
                        <Box sx={stylebox}>
                            <h4>address*</h4>
                            <input type="text" onChange={
                                (e) => {
                                    setData({ ...data, location: e.target.value })
                                }
                            } maxLength={100} placeholder='Enter your address here' required style={styleinput} />
                        </Box>
                        <Box sx={stylebox}>
                            <h4>Education*</h4>
                            <input type="text" onChange={
                                (e) => {
                                    setData({ ...data, education: e.target.value })
                                }
                            
                            } placeholder='Enter your Education here' required style={styleinput} />
                        </Box>
                        <Box sx={stylebox}>
                            <h4>Message *</h4>
                            <textarea type="text" onChange={
                                (e) => {
                                    setData({ ...data, cover: e.target.value })
                                }
                            } required rows={7}  style={{
                                width: '100%',

                                borderRadius: '8px',
                                border: '1px solid #D9D9D9',
                                padding: '0 15px',

                            }} />
                        </Box>


                        <Box sx={stylebox}>
                            <h4>Your CV*</h4>
                            <div
                                className="cv-upload-container"
                                onDragOver={(e) => e.preventDefault()}
                                onDrop={(e) => handleFileDrop(e)}
                            >

                                <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="44" height="44" rx="8" fill="#F8C399" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.552 16.9438C14.5 17.2195 14.5 17.5504 14.5 18.2123V23.5C14.5 26.3284 14.5 27.7426 15.3787 28.6213C16.2574 29.5 17.6716 29.5 20.5 29.5H23.5C26.3284 29.5 27.7426 29.5 28.6213 28.6213C29.5 27.7426 29.5 26.3284 29.5 23.5V21.8484C29.5 19.8742 29.5 18.887 28.9229 18.2454C28.8698 18.1864 28.8136 18.1302 28.7546 18.0771C28.113 17.5 27.1258 17.5 25.1516 17.5H24.8713C24.0061 17.5 23.5734 17.5 23.1703 17.3851C22.9488 17.322 22.7353 17.2335 22.5341 17.1216C22.1678 16.9178 21.8618 16.6118 21.25 16L20.8373 15.5873C20.6322 15.3822 20.5297 15.2797 20.422 15.1904C19.9574 14.8053 19.3875 14.5692 18.7867 14.513C18.6473 14.5 18.5023 14.5 18.2123 14.5C17.5504 14.5 17.2195 14.5 16.9438 14.552C15.7302 14.781 14.781 15.7302 14.552 16.9438ZM22.1875 20.5C22.1875 20.1893 22.4393 19.9375 22.75 19.9375H26.5C26.8107 19.9375 27.0625 20.1893 27.0625 20.5C27.0625 20.8107 26.8107 21.0625 26.5 21.0625H22.75C22.4393 21.0625 22.1875 20.8107 22.1875 20.5Z" fill="white" />
                                </svg>

                                <p>CV
                                    Must be word or pdf</p>
                               
                                <input  style={{
                                    borderRadius: '8px',
                                    background: 'var(--brand-colors-orange-orange-500, #F07D21)',
                                    padding: '10px 15px',
                                    border: 'none',
                                    color: '#fff',
                                }} 
                               
                                type='file' 

                                onChange={(e) => {
                                    const file = e.target.files[0];
                                    if (file) {
                                        setFile(file);

                                        alert('File dropped:' + file.name);
                                        console.log(file.name);
                                    }
                                }
                            }
                                id='fileInput'
                                />
                                    
                               
                            </div>

                            <input  style={{
                                borderRadius: '8px',
                                background: loading === 'send' ? 'var(--brand-colors-orange-orange-500, #F07D21)' : loading === 'sending...' ? '#E5E5E5' : 'green',
                                padding: '10px 15px',
                                border: 'none',
                                color: '#fff',
                                width: '100%',
                                height: '50px',
                                fontSize: '16px',
                                marginTop: '20px',


                            }}
                            
                            value={
                                loading
                            }
                            type="submit"  
                            
                            />
                                
                            
                        </Box>

                    </Box>

                </form>
            </Container>
        </div>
    );
}

export default Applcation;






