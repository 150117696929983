import React from 'react';
import img from './2.png'
import { Container } from 'react-bootstrap';
import { Box, Grid, Typography } from '@mui/material';
import {Contx} from '../../../../App'


const Sections2 = () => {
    const {lang} = React.useContext(Contx)
    const data = content[lang]
    return (
        <Box style={{
            display: 'flex',
            direction:lang === 'en'?'ltr':'rtl',
           
        }}>

            <Container  >
                <Grid container spacing={3} sx={{
                    flexDirection: { xs: "column-reverse", md: 'row'},
                    
                }} >
                    <Grid item xs={12} md={6} sx={{
                        display: 'flex',
                        flexDirection:   'column',

                        
                        alignItems: 'start',
                    }}>

                        <Typography variant="h3"  fontWeight={'600'} sx={{
                            textAlign: 'start',
                            margin: '0px 16px',
                           
                        }}>
                            {data.h}
                        </Typography>

                        <Typography variant="h5"  fontWeight={'300'} sx={{
                            textAlign:'justify',
                            margin: '5px 16px',
                            fontSize: '25px',
                            color: 'rgba(0, 0, 0, 0.5)',
                            lineHeight: '36px',

                           
                        }}>
                            {data.p}
                        </Typography>
                       
                       

                        </Grid>

                        <Grid item xs={12} md={6} sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <img src={img} width={'65%'} alt="" />

                        </Grid>

                </Grid>
            </Container>

            <Box sx={{
                mb:{xs:'5',md:'5'}
            }}></Box>
            
        </Box>
    );
}

export default Sections2;



const en = {
    h: 'WHY Shubber SB',

    p: `Shubber SB is a leading pharmaceutical distribution company in Iraq. We offer a wide range of services to our customers, including warehousing, sales, distribution, regulatory compliance, and marketing intelligence. We are committed to providing our customers with the highest quality products and services, and we are always looking for ways to improve our operations.

    We understand that patient safety is paramount, and we take all necessary precautions to ensure that the products we distribute are genuine and of the highest quality. We also have a team of experienced professionals who can provide our customers with expert advice and support.
    `,
}


const ar = {
    h: `لماذا مكتب شُبر العلمي؟`,

    p: `مكتب شُبر العلمي هي شركة رائدة في توزيع الأدوية في العراق. نقدم مجموعة متنوعة من الخدمات لعملائنا، بما في ذلك التخزين، والمبيعات، والتوزيع، والامتثال للقوانين، والاستخبارات التسويقية. نحن ملتزمون بتقديم منتجات وخدمات عالية الجودة لعملائنا، ونبحث دائمًا عن وسائل لتحسين عملياتنا. نحن ندرك أن سلامة المريض أمر بالغ الأهمية، ونتخذ جميع الاحتياطات اللازمة لضمان أن المنتجات التي نوزعها حقيقية وعالية الجودة. لدينا أيضًا فريق من المحترفين ذوي الخبرة يمكنه تقديم نصائح ودعم خبير لعملائنا`}

const ku = {
    h: `بۆچى "شوبه‌ر ئێس بى"؟`,
    p: `شوبه‌ر ئێس بى" كۆمپانیایه‌كى پێشه‌نگى دابه‌شكردنى ده‌رمانه‌ له‌ عێراق. كۆمه‌ڵێك خزمه‌تگوزاریى به‌رفراوان پێشكه‌ش به‌ كڕیاره‌كانمان ده‌كه‌ین، له‌وانه‌ش كۆگاكردن و فرۆشتن و دابه‌شكردن و پاپه‌ندبوون به‌ رێنماییه‌كان و ماركیتینگى سه‌ركه‌وتوو. ئێمه‌ پاپه‌ندین به‌ پێشكه‌شكردنى به‌رهه‌م و خزمه‌تگوزارییه‌كان به‌ به‌رزترین كواڵیتى بۆ كڕیاره‌كانمان و هه‌میشه‌ به‌دواى رێگه‌ى نوێدا ده‌گه‌ڕێین بۆ به‌ره‌وه‌پێشه‌وه‌بردنى كاره‌كانمان.
    ئێمه‌ له‌و راستییه‌ تێده‌گه‌ین كه‌ سه‌لامه‌تیى نه‌خۆش له‌ پێش هه‌موو شتێكه‌وه‌یه‌ و بۆ ئه‌و مه‌به‌سته‌ش هه‌موو رێوشوێنێكى پێویست ده‌گرینه‌به‌ر بۆ دڵنیابوون له‌وه‌ى ئه‌و به‌رهه‌مانه‌ى كه‌ دابه‌شیان ده‌كه‌ین ئۆڕجیناڵن و به‌رزترین كواڵیتییان هه‌یه‌. له‌هه‌مان كاتیشدا تیمێكى شاره‌زا و پڕۆفێشناڵ و به‌ئه‌زموونمان هه‌یه‌ كه‌ ده‌توانن ئامۆژكارى و پاڵپشتیى پسپۆرانه‌ پێشكه‌شى كڕیاره‌كانمان بكه‌ن.
    `
}



export const content = {
    en,
    ar,
    ku
}