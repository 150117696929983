import React, { Component } from "react";
import Slider from "react-slick";
import img from './Display.png'
import arro from './arrow.svg'
import { Container } from "react-bootstrap";
export default class AsNavFor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2
    });
  }

  render() {
    return (
      <div>
        <Container style={{
            maxWidth: '75%',
            
        }}>









        
        <Slider arrows={true} lazyLoad="true" centerMode="true" centerPadding="0px" slidesToShow={1} slidesToScroll={1}
        
       
        nextArrow={<SampleNextArrow />}
        prevArrow={<SamplePrevArrow />}
          asNavFor={this.state.nav2}
          ref={slider => (this.slider1 = slider)}
        >
          
         {
            this.props.img.map((item, index) => {
                return (
                    <div key={index} style={{
                        position: 'relative',
                        
                    }}>
                    <img src={item.the_image} alt="img" className="col-12" width={'100%'} style={{
                        padding: '3%',borderRadius:'60px',
                        height: '42vw',
                        
                    }} />
                   
                    </div>
                )
                })
         }
        </Slider>
        {

      
          this.props.img.length > 5 &&  <Slider {...settings} lazyLoad="true" centerMode="true" centerPadding="20px" 
         nextArrow={<SampleNextArrow />}
         prevArrow={<SamplePrevArrow />}
          asNavFor={this.state.nav1}
          ref={slider => (this.slider2 = slider)}
         slidesToShow={5}
        
          swipeToSlide={true}
          focusOnSelect={true}
          infinite={true}
        >
          {
             this.props.img.map((item, index) => {
                return (
                    <div key={index} style={{
                      width:'100px !important',
                    }} >
                    <img src={item.the_image} alt="img"  style={{
                      width:'100%',
                      height: '150px',
                        padding: '3%',
                        borderRadius: '24px',
                    }} />
                    </div>
                )
                })
         }
         
        </Slider>

  }


          



        </Container>

    
      </div>
    );
  }
}








function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <img src={arro}  alt="" className={className}
      style={{ ...style,transform: 'scale(-3)'}}
      onClick={onClick}/>
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <img  src={arro} alt="" className={className}
        style={{ ...style ,transform: 'scale(3)'}}
        onClick={onClick} />
    );
  }





  var settings = {
   
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };