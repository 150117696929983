import React from 'react';
import Card from '../home/sections/news/card';
import img from './1.png'
import { Container } from 'react-bootstrap';
import { Grid } from '@mui/material';
import api from '../../../api/api';
import { Contx } from '../../../App';
const News = () => {
    const { lang } = React.useContext(Contx)
    const [news, setNews] = React.useState([])
    React.useEffect(() => {
        api.get('/news').then(res => {
            console.log(res.data)
            setNews(res.data.results)
        })
    }, [])

    return (
        <div>
           <Container className='my-5'  >
            <Grid   container spacing={2} >
                {
                    news.map((item,index) => {
                        return (
                            <Grid item xs={12} md={6} lg={3}  key={index}>

                            <Card item={item}  lang={lang}/>
                            </Grid>
                        )
                    })
                }
            </Grid>
           </Container>
        </div>
    );
}

export default News;
