import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { Container } from 'react-bootstrap';
import bg from '../nav/Group.png'
import { Link } from 'react-router-dom';
import icon1 from './icon/1.svg'
import icon2 from './icon/2.svg'
import icon3 from './icon/3.svg'
import icon4 from './icon/4.svg'
import icon5 from './icon/5.svg'
import icon6 from './icon/6.svg'



const Footer = () => {

    const linknav = ['/', 'about', 'partners', 'gallery', 'jobs', 'news', 'certificate', 'contact']

    return (
        <Box sx={{
            bgcolor: '#FFF',
        }}>

            <Container>

                <Grid container spacing={2} gap={10} py={3}>

                    <Grid item xs={5} sm={4} md={2}>

                        <img src={bg} width={'100%'} alt="" />

                        <Typography variant="body1" gutterBottom sx={{
                            textAlign: 'start', my: 2,
                            color: ' var(--font-secoundary, rgba(0, 0, 0, 0.60))'
                        }}>
                            Thank you for being a part
                            of our journey towards a
                            more diverse and inclusive
                            Shubber.
                        </Typography>


                    </Grid>








                    <Grid item xs={12} sm={4} md={2}>
                        <Typography variant="h6" gutterBottom sx={{ textAlign: 'start', my: 2, color: '#3E8343', fontWeight: '600' }}>
                            Company Info
                        </Typography>

                        {
                            ['Home', 'About Us', 'Partners', 'Gallery', 'Jobs', 'News', 'Certificate', 'Contact Us'].map((item, index) => {
                                return (
                                    <Typography key={index} variant="body1" sx={{
                                        textAlign: 'start', my: 2,
                                        lineHeight: '15px',
                                    }}>
                                        <Link to={linknav[index]} style={{
                                            textDecoration: 'none', color: ' var(--font-secoundary, rgba(0, 0, 0, 0.50))',
                                            fontSize: '16px', fontWeight: '600',

                                        }}>
                                            {item}
                                        </Link>
                                    </Typography>
                                )
                            })
                        }



                    </Grid>




                    <Grid item xs={12} sm={12} md={3.3}>
                        <Typography variant="h6" gutterBottom sx={{ textAlign: 'start', my: 2, color: '#3E8343', fontWeight: '600' }}>
                            Get In Touch
                        </Typography>


                        <Typography variant="body1" sx={{
                            textAlign: 'start', my: 2,
                            lineHeight: '15px',
                        }}>
                            <a href="tel:+964 780 919 0218" style={{
                                textDecoration: 'none', color: ' var(--font-secoundary, rgba(0, 0, 0, 0.50))',
                                fontSize: '16px', fontWeight: '600',

                            }}>
                                <img src={icon1} alt="" width={40} className='px-2' /> +964 780 919 0218
                            </a>
                        </Typography>


                        <Typography variant="body1" sx={{
                            textAlign: 'start', 
                            lineHeight: '15px',
                        }}>
                            <a href="mailto:info@alshubber.com" style={{
                                textDecoration: 'none', color: ' var(--font-secoundary, rgba(0, 0, 0, 0.50))',
                                fontSize: '16px', fontWeight: '600',

                            }}>
                                <img src={icon3} alt="" width={40} className='px-2' />
                                info@alshubber.com
                            </a>
                        </Typography>
                        <span style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                        }}>
 <img src={icon2} alt="" width={40} className='px-2' />
                            <Typography variant="body1" sx={{
                                textAlign: 'start', my: 2,
                                lineHeight: '18px',color: ' var(--font-secoundary, rgba(0, 0, 0, 0.50))',
                                    fontSize: '16px', fontWeight: '600',
                            }}>
                               
                                   
                                    Baghdad - albetaween <br />
                                    way : 101 District 17 Building 10
                                
                            </Typography>

                        </span>






                    </Grid>

















                </Grid>

            </Container>

            <hr  />
            

            <Container>

            <Box sx={{
                bgcolor: '#FFF',
                pb: 2,
                textAlign: 'center',
                color: ' var(--font-secoundary, rgba(0, 0, 0, 0.50))',
                fontSize: '16px', fontWeight: '600',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}>
                <Box>
                Subber sb2023 @copywrite
                </Box>

                <Box >
                    <img className='mx-1' src={icon4} width={30} alt="" />
                    <img className='mx-1' src={icon5} width={30} alt="" />
                    <img className='mx-1' src={icon6} width={30} alt="" />
                </Box>



            </Box></Container>

        </Box>
    );
}

export default Footer;
