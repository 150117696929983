
import './App.css';
import Navbar from './components/nav/nav';
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './components/pages/home/home';
import About from './components/pages/about/about';
import Partners from './components/pages/partners/partners';

import Galary from './components/pages/galary/galary';
import Jops from './components/pages/jops/jops';
import Item from './components/pages/jops/item';
import News from './components/pages/news/news';
import Descriptionnews from './components/pages/news/descriptionnews';

import Certificate from './components/pages/Certificateslide/Certificate';
import Contact from './components/pages/contact/contact';
import Footer from './components/footer/footer';
import Not from './components/notfication/not';

import Loder from './components/loder/loder';






export const Contx = React.createContext()






function App() {
  const [lang, setLang] = React.useState('en');


  const [loading, setLoading] = React.useState(true);


  React.useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 5000)
  }, [])







  return (<>
  { loading ? <Loder /> :
    <Contx.Provider value={{ lang, setLang }}>
      <div className="App">

        <Not />


        <BrowserRouter>
          <Navbar />

          <Routes>


            <Route path="/" element={<Home />} />

            <Route path="/about" element={<About />} />
            <Route path="/partners" element={<Partners/>} />
            <Route path="/gallery" element={<Galary/>} />
            <Route path="/jobs" element={<Jops/>} />
            <Route path="/jobs/:id" element={<Item/>} />
            <Route path="/news" element={<News/>} />
            <Route path="/news/:id" element={<Descriptionnews/>} />
            <Route path="/Certificate" element={<Certificate/>} />
            <Route path="/contact" element={<Contact/>} />







          </Routes>

          <Footer />
        </BrowserRouter>



      </div>
    </Contx.Provider>}</>
  );
}

export default App;
