import { Box, Typography } from '@mui/material';
import React from 'react';
import img from './job.png'
import Taps from './taps/taps';
import { Contx } from '../../../App';
import api from '../../../api/api';
import { useParams } from 'react-router';

const Item = () => {
    const { lang } = React.useContext(Contx)
    const [job, setjob] = React.useState([])
    const {id} = useParams()
    React.useEffect(() => {
        api.get(`/Jobs/${id}/`).then(res => {
            setjob(res.data)
            console.log(res.data)
        }).catch(err => {
            console.log(err.massege)
        })
    }, [])
    return (
        <div>
            <Box sx={{ width: '100%' ,backgroundColor:'#fff',
            border:'solid 1px #D9D9D9',
            padding:'10px',
        }}>
                <img src={img} width={78} alt="" />
                <Typography style={{
                    fontWeight: 'bold',
                    fontSize: '22px',
                    lineHeight: '36px',
                   
                    marginTop:'15px',
                }}>
                    {
                        
                        lang === 'en' ? job.title_en : lang === 'ar' ? job.title_ar : job.title_ku
                    }
                </Typography>

                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'start', marginTop:'10px' }}>
                    <Typography style={{
                        fontWeight: 'bold',
                        fontSize: '14px',
                        lineHeight: '36px',
                       
                        color:'#fff',
                        backgroundColor:'#3E8343',
                        padding:'5px 10px',
                        borderRadius:'5px',
                    }}>
                        
                    {
                    job.location
                    }

                    </Typography> 
                    </Box>

                    

            </Box>
            <Taps job={job} />
        </div>
    );
}

export default Item;
