import React, { Component } from "react";
import Slider from "react-slick";
import img1 from './img/image 7.svg'
import img2 from './img/image 8.svg'
import img3 from './img/image 9.svg'
import img4 from './img/image 10.svg'
import img5 from './img/image 11.svg'
import img6 from './img/image 12.svg'
import img7 from './img/image 13.svg'
import img8 from './img/johnson.png'


export default class MultipleItems extends Component {

  render() {
    const img = [img1, img2, img3, img4, img5, img6, img7, img8]
    const linkpar =[
      `https://www.sanofi.com/en`,
      `https://www.lilly.com/`,
      `https://www.pfizer.com/`,
      `https://us.pg.com/`,
      `https://moleac.com/`,
      `https://www.organon.com/`,
      `https://www.viatris.com/en`,
      `https://www.janssen.com/`,
    ]
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 3,
      autoplay: true,
      autoplaySpeed: 2000,
      cssEase: "linear",
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        }
      ]

    };
    return (
      <div className="mt-5">
       
        <Slider {...settings} className="box-par">
          {
              img.map((item,index)=>{
                  return(
                    <div>

                   
                    <div style={{
                        display:'flex',
                        justifyContent:'center',
                        alignItems:'center',
                        verticalAlign:'middle',
                        height:'100px',
                       

                    }} >
                   <img src={item} className="img-partner" alt="" width="100" />
                  </div> </div>
                  )
              })
          }
         
        </Slider>
      </div>
    );
  }
}

