import { Typography } from '@mui/material';
import React from 'react';
import { Container } from 'react-bootstrap';
import Slider from './slider';
import { Contx } from '../../../App'

const Partners = () => {
    const { lang } = React.useContext(Contx)
    const data = content[lang]
    
    return (
        <div className='my-5 py-5'>
            <Container dir={
                lang === 'en' ? 'ltr' : 'rtl'
            
            }>
                 <h1 style={{
                    textAlign: 'start',
                 }}>{
                        data.h
                    
                 }</h1>

                 <Typography variant="body1" gutterBottom style={{
                        
                        textAlign: 'justify',
                        maxWidth: '900px',
                        fontSize: '18px',
                    }}>
                       
                       {
                            data.p
                       }
                         </Typography>



                   <Slider />
            </Container>
           
        </div>
    );
}

export default Partners;

const en = {
    h:'To our partners',
    p:`To our partners, we extend our heartfelt gratitude for your unwavering support, dedication, and shared vision. It is through our collaboration that we are able to make a meaningful difference in the lives of our customers and communities.
    As we move forward, we reaffirm our commitment to cultivating strong, long-term partnerships. We will continue to invest in open communication, mutual respect, and shared goals, ensuring that our partnerships remain resilient, dynamic, and impactful.
     `}
  
  const ar = {
    h:'لشركائنا',
    p:`نحن نمد لكم امتناننا العميق لدعمكم وتفانيكم ورؤيتكم المشتركة.
    من خلال تعاوننا ، نحن قادرون على إحداث فرق معنوي في حياة عملائنا ومجتمعاتنا.
    مع مرور الوقت ، نؤكد التزامنا بتعزيز الشراكات القوية وطويلة الأمد. سنستمر في الاستثمار في التواصل المفتوح والاحترام المتبادل والأهداف المشتركة ، مما يضمن أن تظل شراكاتنا قوية وديناميكية ومؤثرة.
    `}
  
    const ku = {
      h:'بۆ شریکانمان',
      p:`بۆ شریکانمان بە خێربەرایەتی و پێشکەشکردنەوەی خۆشەویستان، پێشنیاری وەرگرتنی خۆشەویستان و بینینی یەکگرتووەکانیان. 
      لە ڕێکخراوی ئێمە، ئێمە بە تەنهاوی کاریگەریمان دەبێت کە ئەوەی بەرزترین کاریگەری ئەمریکا بەردەوام بکەن. 
      بە کاردێکردنی ئێمە، ئێمە دەتوانین دەستی بکەین بە کاریگەری زۆربەیی لە ژیانی کەلەپەڵەکانمان و کۆمەڵگایانمان بکەین.
      بە دەست دەکەوێت ئەوەی ئێمە دوایین کەس نەبینین کە لە بەرزترین کاریگەری ئەمریکا دەبێت.
      `}
  
  export const content = {
    en,
    ar,
    ku
  }