import React from 'react';
import Section1 from './sections/section1';
import Sections2 from './sections/sections2';
import Section3 from './sections/section3';
import Section4 from './sections/section4';
import { Contx } from '../../../App';

const About = () => {
    const { lang } = React.useContext(Contx)

    return (
        <div>
            <Section1 lang={lang}/>
            <br /><br /><br />
            <Sections2 lang={lang}/>
            <br /><br /><br />
            <Section3 lang={lang}/>
            <br /><br /><br />
            <Section4   lang={lang}/>
            <br /><br /><br />
        </div>
    );
}

export default About;
