import { Box, Button, Grid } from '@mui/material';
import React from 'react';
import { Container } from 'react-bootstrap';
import icon1 from './icon/1.svg'
import icon2 from './icon/2.svg'
import icon3 from './icon/3.svg'
import icon4 from './icon/4.svg'
import { Contx } from '../../../../App';

const Overviow = (props) => {
    const { lang } = React.useContext(Contx)
    return (
        <div>
            <Container className='my-5' dir={
                lang === 'en' ? 'ltr' : 'rtl'
            } >
                <Grid container justifyContent={'space-between'} spacing={2}>
                    <Grid item xs={12} md={6}  >
                        <pre style={{
                            whiteSpace: 'pre-wrap',
                            wordWrap: 'break-word',
                            fontSize: '18px',
                            lineHeight: '24px',
                            color: '#333333',
                            marginTop: '20px',
                            marginBottom: '20px',
                           fontFamily:'sans-serif',
                            textAlign: 'start',

                        }} >
                            {
                                lang === 'en' ? props.job.content_en : lang === 'ar' ? props.job.content_ar : props.job.content_ku
                            }

                        </pre>
                    </Grid>





                    <Grid item xs={12} md={5}>
                        <Box  sx={{ display: 'flex', justifyContent: 'end' }}>
                            <Button variant="contained" onClick={
                                () => {
                                    props.activeKey('Application')
                                }
                            } style={{
                                backgroundColor: '#008ABC',
                                color: '#fff',
                                width: '150px',
                                height: '50px',
                                fontSize: '16px',


                                borderRadius: '16px',
                            }}>
                                Apply Now
                            </Button></Box>

                            <Box  sx={{borderRadius:'10px', display: 'flex', justifyContent: 'center',backgroundColor:'#fff',width:'100%',gap:10,py:3,my:2 }}>

                                <Box  >
                                    <img src={icon1} alt="icon1" width={38} className='mb-2' />
                                    <h5 className='m-0'>Working Day</h5>
                                    <p>{
                                        props.job.working_days

                                        } day week</p>

                                </Box>
                                <Box >
                                    <img src={icon2} width={38} alt="icon2" className='mb-2' />
                                    <h5 className='m-0'>Job Location</h5>
                                    <p>{props.job.location} , Iraq</p>

                                </Box>
                               

                            </Box>
                            <Box  sx={{borderRadius:'10px', display: 'flex', justifyContent: 'center',backgroundColor:'#fff',width:'100%',gap:10,py:3,my:2,position:'relative' }}>
                                    <p style={{
                                        position: 'absolute',
                                        top:'15px',
                                        left:'15px',
                                    }}>Job Overview</p>
                                <Box mt={4} >
                                    <img src={icon3} alt="icon3" width={38} className='mb-2' />
                                    <h5 className='m-0'>Job Posted:</h5>
                                    <p>{
                                        props.job.created
                                        }</p>

                                </Box>
                                <Box mt={4}>
                                    <img src={icon4} width={38} alt="icon4" className='mb-2' />
                                    <h5 className='m-0'>Job expire in:</h5>
                                    <p>{
                                        props.job.job_expire
                                        }</p>

                                </Box>
                               

                            </Box>
                    </Grid>
                </Grid>

            </Container>
        </div>
    );
}

export default Overviow;
