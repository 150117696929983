import React from 'react';
import img from './1.svg'
import { Container } from 'react-bootstrap';
import { Box, Grid, Typography } from '@mui/material';
import img2 from './2.png'
import { Contx } from '../../../../../App';



const Mission = () => {
    const { lang } = React.useContext(Contx)
    const data = content[lang]
    return (
        <div style={{
            background: `url(${img})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            marginTop: '0',
            paddingTop: '0',
            direction: lang === 'en' ? 'ltr' : 'rtl',
        }}>
            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Box my={10}>
                            <Box >
                                <Typography variant="h3" align='start' sx={{


                                    marginTop: '1rem',
                                    marginBottom: '1rem',
                                    fontFamily: 'Poppins',
                                }}>
                                   
                                    {data.h1}
                                </Typography>
                                <Typography variant="h6" align='justify' sx={{

                                    marginTop: '1rem',
                                    marginBottom: '1rem',
                                    maxWidth: '530px',
                                }}>
                                    {data.p1}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography variant="h3" align='start' sx={{


                                    marginTop: '1rem',
                                    marginBottom: '1rem',
                                    fontFamily: 'Poppins',
                                }}>
                                    {data.h2}
                                </Typography>
                                <Typography variant="h6" align='justify' sx={{

                                    marginTop: '1rem',
                                    marginBottom: '1rem',
                                    maxWidth: '530px',
                                }}>
                                   
                                   {data.p2}
                                    </Typography>
                            </Box>
                        </Box>

                    </Grid>
                    <Grid item xs={12} md={6} sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',


                    }}>

                        <img src={img2} style={{
                            width: '70%',
                            

                        }} alt="" />
                    </Grid>
                </Grid>

            </Container>
        </div>
    );
}

export default Mission;


const en = {
    h1: 'Our Mission',
    p1: `We at Shubber SB are committed to providing our customers with the highest quality service and products possible. 
    We take our responsibility to our customers very seriously, and we comply with all applicable standards and regulations. 
    
    `,
    h2: 'Our Vision',
    p2: `Our vision is to be the most valued company that helps people feel good, look good, and get more out of life.
    We are committed to creating a better future for everyone, and we will do this by innovating and providing high-quality products and services.
    We believe that everyone deserves to live a healthy and happy life, and we are dedicated to improving people's lives in any way we can.`
}


const ar = {
    h1: 'مهمتنا',
    p1:`نحن في شبر العلمي ملتزمون بتقديم أعلى جودة من الخدمة والمنتجات لعملائنا. نأخذ مسؤوليتنا تجاه عملائنا بجدية كبيرة، ونلتزم بجميع المعايير واللوائح ذات الصلة.`,
    h2: 'رؤيتنا',
    p2: `رؤيتنا هي أن نكون الشركة الأكثر قيمة التي تساعد الناس على الشعور بالراحة، وتحقيق المظهر الجيد، والاستفادة أكثر من الحياة. نحن ملتزمون بخلق مستقبل أفضل للجميع، وسنقوم بذلك من خلال الابتكار وتقديم منتجات وخدمات عالية الجودة. نعتقد أن الجميع يستحقون أن يعيشوا حياة صحية وسعيدة، ونحن مكرسون لتحسين حياة الناس بأي طريقة`,
}

const ku = {
    h1:`ئةركمان`,
    p1:`ئێمه‌ له‌ "شوبه‌ر ئێس بى" پاپه‌ندین به‌ پێشكه‌شكردنى خزمه‌تگوزارى و به‌رهه‌م به‌ به‌رزترین كواڵیتى بۆ كڕیاره‌كانمان.
    ئێمه‌ به‌رپرسیاره‌تییه‌كانمان له‌به‌رامبه‌ر كڕیاره‌كانمان زۆر به‌ جددى وه‌رده‌گرین و پاپه‌ندین به‌ هه‌موو سته‌نده‌ر و رێسا كارپێكراوه‌كان.
    
    `,
    h2:`دیدگه‌مان`,
    p2:`دیدگه‌مان ئه‌وه‌یه‌ ببین به‌ گرنگترین و ناوبانگترین كۆمپانیا كه‌ یارمه‌تیى خه‌ڵك بدات بتوانن به‌ باشى هه‌ست بكه‌ن و باش ده‌ركه‌ون و زیاترین چێژ له‌ ژیان وه‌ربگرن.
    ئێمه‌ پاپه‌ندین به‌ خوڵقاندنى داهاتوویه‌كى باشتر بۆ هه‌مووان و ئه‌م ئامانجه‌ش به‌دى دێنین له‌ڕێگه‌ى داهێنان و پێشكه‌شكردنى به‌رهه‌م و خزمه‌تگوزاریى كواڵیتى به‌رز.`
}

const content = {
    en,
    ar,
    ku
}
