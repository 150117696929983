import React, { Component } from "react";
import Slider from "react-slick";
import arrow from './arrow.svg'
import { Box } from "@mui/material";

export default class SimpleSlider extends Component {
    constructor(props) {
      super(props);
      this.slider = React.createRef();
    }
  
    handlePrevClick = (e) => {
    e.preventDefault();
      this.slider.current.slickPrev();
    }
  
    handleNextClick = (e) => {
    e.preventDefault();

      this.slider.current.slickNext();
    }
  
    render() {
      const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
      };
  
      return (
        <div style={{
          borderRadius: '24px',
        }}>
          
          <Slider ref={this.slider} {...settings}>
            {this.props.images.map((item, index) => (
              <div key={index}>
                <img src={item.image} width={'100%'} style={{ borderRadius: '24px' }} alt="" />
              </div>
            ))}
          </Slider>
  
          <Box sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            direction: 'ltr'
           
          }}>
            <div>
              {
                this.props.lang === 'en' ? this.props.item.title_en : this.props.lang === 'ar' ? this.props.item.title_ar : this.props.item.title_ku
              }
            </div>
            <div>
              <img
                src={arrow}
                className="px-2 arrslide"
                alt=""
                style={{ transform: 'rotate(180deg)',zIndex: '10' }}
                onClick={this.handlePrevClick}
              />
              <img
                className="px-2 arrslide"
                style={{ transform: 'rotate(0deg)',zIndex: '10' }}
                src={arrow}
                alt=""
                onClick={this.handleNextClick}
              />
            </div>
          </Box>
        </div>
      );
    }
  }