import React from 'react';
import Cards from './cards';
import { Container } from 'react-bootstrap';
import { Grid } from '@mui/material';
import api from '../../../api/api';

const Jops = () => {
    const [jops, setjops] = React.useState([])

    React.useEffect(() => {
        api.get('/Jobs').then(res => {
            setjops(res.data.results)
            console.log(res.data)
        }).catch(err => {
            console.log(err)
        })
    }, [])
    return (
        <div>
            <Container>
                <Grid m={1} container spacing={2}>
                    {
                        jops.map((item, index) => {
                            return (
                                <Grid item xs={12} md={6} lg={4} xl={4} key={index}>
                                    <Cards item={item} />
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </Container>
        </div>
    );
}

export default Jops;
