import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';


const Card = (props) => {
    return (
        <Box sx={{
            width: '300px',
            height: '510px',
            background: 'white',
            borderRadius: '24px',
            direction: props.lang === 'en' ? 'ltr' : 'rtl',
        }}>
            <Box>
                <img src={props.item.cover_image} width={'100%'} style={{
                    borderTopLeftRadius: '24px',
                    borderTopRightRadius: '24px',
                    height: '200px',
                }} alt="" />
            </Box>
            <Box p={2} sx={{
                display: 'flex',
                justifyContent: 'space-between',
            }}>
                <p style={{
                    color:'rgba(240, 125, 33, 1)',
                    textAlign: 'start',
                    margin: '0px',
                }}>News</p> 
               <div style={{
                borderRadius: '20px',
                background: 'rgba(62, 131, 67, 1)',
                color: 'white',
             
               
               
               }}>
                <p style={{
                    padding: '4px 6px',
                    margin: '0px',  
                    fontSize: '12px',
                }}>{
                    props.item.created
                }</p>
               </div>
            </Box>


            <Typography variant="h6"  fontWeight={'600'} sx={{
                textAlign: 'start',
                margin: '0px 16px',
                fontSize: '16px',
            }}>
              {
                props.lang === 'en' ? props.item.title_en : props.lang === 'ar' ? props.item.title_ar : props.item.title_ku
              }
            </Typography>

            <Typography variant="h5"  fontWeight={'300'} sx={{
                textAlign: 'start',
                margin: '5px 16px',
                fontSize: '18px',
                color: 'rgba(0, 0, 0, 0.5)',
               
            }}>
                {
               props.lang === 'en' ? props.item.excerpt_en : props.lang === 'ar' ? props.item.excerpt_ar : props.item.excerpt_ku

                }...
            </Typography>
            <Box sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                margin: '10px 16px',
            }}>
                <Link to={`/news/${props.item.id}`} style={{
                    textDecoration:'none'
                }}>
               
            <Button  sx={{
                borderRadius: '37px',
                borderColor:'rgba(243, 151, 77, 1)',
                color:'rgba(243, 151, 77, 1)'
            }} variant="outlined">
                <Typography variant="h6"  fontWeight={'600'} sx={{
                    textAlign: 'start',
                    
                    fontSize: '12px',
                }}>
                    {'Learn More >>'} 
                </Typography>
            </Button>
            </Link>
            </Box>
        </Box>
    );
}

export default Card;

