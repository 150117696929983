import React, { useEffect } from 'react';
import Slide from './slide';
import Slidevideo from './slidevideo';
import api from '../../../api/api';


const Galary = () => {


    const [vid, setvid] = React.useState([])
    const [img, setimg] = React.useState([])
 

    useEffect(() => {
        
        api.get('/galleries/?limit=1000').then(res => {
            
            setimg(res.data.results)
            console.log(res.data)
            
        }).catch(err => {
            console.log(err)
           
        })

       
    }, [])

   



    return (
        <div>
        
            <Slide img={img} />


            <br /><br /><br />

            {/* <Slidevideo  /> */}

            
            
        </div>
    );
}

export default Galary;
